






















































































































































































import Vue from "vue";

export default Vue.extend({
  name: "PrivacyPolicy",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  data: () => ({}),
  methods: {
    goToHome () {

    },
    goToAboutUs () {

    },
    goToContact () {

    },
    goToSignUp () {
      this.$router.push({ name: "landing" });
    },
    goToLogIn () {
      this.$router.push({ name: "Login" });
    },
  },
});
